import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/common/layout'
import ReactMarkdown from "react-markdown";
import {replaceUploadsDir} from "../utils/gatsby-node-helpers";
import SEO from "../components/common/seo";
import Share from "../components/common/share";
import rehypeRaw from "rehype-raw";
// import styled from "styled-components";
// import Flickity from "react-flickity-component";

const ArticleTemplate = (props) => {
    const lang = props.pageContext.locale.path.default;
    const schema =
        {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": props.data.strapiArticle.title,
            "image": "https://www.tecalis.com" + props.data.strapiArticle.media.localFile.publicURL,
            "author": {
                "@type": "Person",
                "name": props.data.strapiArticle.area
            },
            "publisher": {
                "@type": "Organization",
                "name": "Tecalis",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.tecalis.com" + require("../images/logo-tecalis-square.jpg").default
                }
            },
            "datePublished": props.data.strapiArticle.date_iso,
            "dateModified": props.data.strapiArticle.date_iso,
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.tecalis.com/"
            },
        };

    return (
        <Layout pageContext={props.pageContext} cookies={props.data.cookies}>
            <SEO lang={lang}
                 title={props.data.strapiArticle.title + " | Insight - Tecalis"}
                 description={props.data.strapiArticle.description}
                 image={props.data.strapiArticle.media.localFile.publicURL}
                 schemaMarkup={schema}/>
            {/** HEADER TECH INSIGHTS NEWS
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14"
                     style={{background: "url('" + require('../images/techinsights_bg.png').default + "') no-repeat center fixed", backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">

                            <h3 align="left" className="text-white spacing">
                                <span className="mr-2">{props.data.strapiArticle.title}</span> <span className="color-tecalis font-weight-bold read"> {props.data.strapiArticle.lecture_time} min.</span>
                            </h3>

                            {/** Heading */}
                            <h1 className="display-4 font-weight-black text-white spacing">
                                {props.data.strapiArticle.description}
                            </h1>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>


            {/** POST TECH
             ================================================== */}

            {/** HEADER
             ================================================== */}
            <section className="pt-8 pt-md-11 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-offset-2">

                            {/** Authors */}
                            <div className="row align-items-center py-5 border-top border-bottom mb-5">
                                <div className="col-auto">
                                    {/** Avatar */}
                                    <div className="avatar avatar-sm">
                                        <img src={require("../../static/favicon.ico").default} alt="author" title="author"
                                             className="avatar-img rounded-circle tech"/>
                                    </div>
                                </div>

                                <div className="col ml-n5">
                                    {/** Name */}
                                    <h6 className="text-uppercase mb-0 spacing">
                                        {props.data.strapiArticle.area}
                                    </h6>

                                    {/** Date */}
                                    {/*<time className="font-size-sm text-muted spacing" dateTime="2020-01-01">

                                        </time>*/}
                                </div>

                                <div className="col-auto">
                                    {/** Share */}
                                    <span className="h6 text-uppercase spacing text-muted d-none d-md-inline mr-4">Compartir:</span>

                                    {/** Icons */}
                                    <Share
                                        socialConfig={{
                                            twitterHandle: props.data.site.siteMetadata.twitterHandle,
                                            config: {
                                                url: props.location.href,
                                                title: props.data.strapiArticle.title,
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            {/** Cuerpo post */}

                            <div className="cuerpo-post check-list">
                                {/** Heading cuerpo */}
                                <ReactMarkdown children={replaceUploadsDir(props.data.strapiArticle.content)} rehypePlugins={[rehypeRaw]}/>

                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>

                            </div>
                            {/** ./ Fin Cuerpo post */}

                        </div>
                        {/** /.col */}
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>
        </Layout>
    )
}

export default ArticleTemplate

export const articlePageQuery = graphql`
    query ArticleTemplate($id: String!, $lang: String!) {
        strapiArticle(id: { eq: $id }) {
            title
            description
            content
            area
            lecture_time
            date_iso: updated_at(formatString: "YYYY-MM-DDThh:mm:ssTZD")
            media {
                name
                localFile {
                    publicURL
                }
            }
        }
         cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
        site {
            siteMetadata {
                title
                twitterHandle
            }
        }
    }
    `;
